import { ISumSalesList } from '@dataTypes/totalSalesTypes';
import React from 'react';

interface SumSalesListProps {
  item: ISumSalesList | undefined;
}

const SumSalesList = ({ item }: SumSalesListProps) => {
  return (
    <div className="sum_sales_list_wrapper">
      <div className="sum_money_wrapper purple">
        <div className="title">총매출</div>
        <div className="money">
          {item?.totalSales ? item.totalSales.toLocaleString('ko-KR') : 0}
        </div>
      </div>
      <div className="sum_money_wrapper red">
        <div className="title">신용카드</div>
        <div className="money">{item?.cardSales ? item.cardSales.toLocaleString('ko-KR') : 0}</div>
      </div>
      <div className="sum_money_wrapper green">
        <div className="title">마일리지</div>
        <div className="money">{item?.cashSales ? item.cashSales.toLocaleString('ko-KR') : 0}</div>
      </div>
      <div className="sum_money_wrapper orange">
        <div className="title">기타</div>
        <div className="money">{item?.etcSales ? item.etcSales.toLocaleString('ko-KR') : 0}</div>
      </div>
    </div>
  );
};

export default SumSalesList;
