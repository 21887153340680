import { IStoreRegisterRequest } from '@dataTypes/storeRegisterTypes';
import { instance } from './index';

export const getCheckStoreIdDuplicate = async (storeId: number) => {
  const response = await instance.get(`/master/check/store-duplicate/${storeId}`);
  return response.data;
};

export const storeRegisterByMaster = async (data: IStoreRegisterRequest) => {
  const response = await instance.post(`/master/store/register`, data);
  return response.data;
};
