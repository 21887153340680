import React, { useEffect, useState } from 'react';
import PerMachineItem from './PerMachineItem';
import { Button } from 'react-bootstrap';
import bottomArrowIcon from '@assets/icon/bottom_arrow_icon(white).png';
import calendarIcon from '@assets/icon/calendar_icon(block_color).png';
import SelectDate from './SelectDate';
import dayjs from 'dayjs';
import { perMachineTotalSalesData } from 'types/perMachineTypes';
import ModifyMachineModal from './ModifyMachineModal';
import Cookies from 'js-cookie';
import { AUTH } from '@dataTypes/authTypes';
import { useRecoilValue } from 'recoil';
import storeInfo from '../../recoil/storeInfo/atom';
import { useGetMachineTotalSalesList } from '@hooks/perMachine/useGetMachineTotalSalesList';
import Loading from '@components/common/loading/LoadingSpinner';

const PerMachineContainer = () => {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedDateVal, setSelectedDateVal] = useState(new Date());
  const [sortedData, setSortedData] = useState<perMachineTotalSalesData[]>([]);
  const [isSorted, setIsSorted] = useState(false);
  const [showModify, setShowModify] = useState(false);
  const authority = Cookies.get('smartlab_authority');
  const convertDate = () => {
    return dayjs(selectedDateVal).format('YYYY-MM-DD');
  };
  const storeInfoState = useRecoilValue(storeInfo);
  const { getPerMachineSaleData, getPerMachineSaleDataLoading } = useGetMachineTotalSalesList(
    storeInfoState.currentStoreId,
    convertDate(),
  );

  useEffect(() => {
    if (getPerMachineSaleData) {
      setSortedData(getPerMachineSaleData);
    }
  }, [getPerMachineSaleData]);

  const handleSortByRevenue = () => {
    let updateSortedData = [];

    if (!isSorted) {
      updateSortedData =
        sortedData &&
        [...sortedData].sort((a, b) => a.card + a.cash + a.etc - (b.card + b.cash + b.etc));
    } else {
      updateSortedData =
        sortedData &&
        [...sortedData].sort((a, b) => b.card + b.cash + b.etc - (a.card + a.cash + a.etc));
    }

    setIsSorted((prevState) => !prevState);
    setSortedData(updateSortedData);
  };

  const handleSalesDetailShow = () => setShowDatePicker(true);
  const handleSalesDetailClose = () => setShowDatePicker(false);

  return (
    <div className="per_machine_wrapper">
      <div className="date_wrapper" onClick={handleSalesDetailShow}>
        <span className="date">{dayjs(selectedDateVal).format('YYYY-MM-DD')}</span>
        <img src={calendarIcon} className="calendar_icon" alt="달력 아이콘" />
      </div>
      <SelectDate
        show={showDatePicker}
        handleClose={handleSalesDetailClose}
        setSelectedDateVal={setSelectedDateVal}
      />

      <div className="btn_wrapper">
        {authority !== AUTH.USER && (
          <Button className="nickname_btn" onClick={() => setShowModify(true)}>
            단말기별 별칭설정
          </Button>
        )}
        <Button className="order_by_btn" onClick={handleSortByRevenue}>
          <img
            src={bottomArrowIcon}
            className={`bottom_arrow_icon ${!isSorted ? 'active' : ''}`}
            alt="화살표"
          />
          <span>매출순 정렬</span>
        </Button>
      </div>

      <div className="per_item_wrapper">
        {getPerMachineSaleDataLoading ? (
          <div className="loading_wrapper">
            <Loading />
          </div>
        ) : !getPerMachineSaleDataLoading && sortedData.length === 0 ? (
          <div className="no_data">데이터가 없습니다</div>
        ) : (
          <>{sortedData?.map((list, index) => <PerMachineItem key={index} item={list} />)}</>
        )}
      </div>

      <ModifyMachineModal handleClose={() => setShowModify(false)} show={showModify} />
    </div>
  );
};

export default PerMachineContainer;
