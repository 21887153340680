import { QueryFunctionContext, useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { TOTALSALES } from '@components/totalSales/queryKey/queryKey';
import { useEffect, useMemo, useState } from 'react';
import { ITotalSalesItem, ITotalSalesResponse, PAYMETHOD } from '@dataTypes/totalSalesTypes';
import Cookies from 'js-cookie';
import { AUTH } from '@dataTypes/authTypes';
import {
  getTotalSales,
  getTotalSalesWithMaster,
  getTotalSalesWithTotalMachine,
} from '@apis/totalSales';

interface useGetTotalSalesListsProps {
  startDate: string;
  endDate: string;
  catId: string;
  method: PAYMETHOD;
  storeId: number | undefined;
}

export const useGetTotalSalesLists = ({
  startDate,
  endDate,
  catId,
  method,
  storeId,
}: useGetTotalSalesListsProps) => {
  const queryClient = useQueryClient();
  const [searchTrigger, setSearchTrigger] = useState(false);
  const [totalSalesLists, setTotalSalesLists] = useState<ITotalSalesItem[]>([]);
  const [lastpage, setLastPage] = useState(false);

  useEffect(() => {
    if (searchTrigger) {
      queryClient.invalidateQueries([TOTALSALES.TOTALSALESLISTS]);
      remove();
      setTotalSalesLists([]);
    }
  }, [searchTrigger]);

  const fetchSalesData = (pageParam: number) => {
    // const authority = Cookies.get('smartlab_authority');
    // if (authority === AUTH.MASTER) {
    //   return getTotalSalesWithMaster(startDate, endDate, catId, method, pageParam);
    // } else {
    if (catId === '') {
      return getTotalSalesWithTotalMachine(startDate, endDate, storeId, method, pageParam);
    } else {
      return getTotalSales(startDate, endDate, catId, method, pageParam);
    }
    // }
  };

  const {
    data,
    isLoading,
    isFetching,
    isError,
    isFetchingNextPage,
    remove,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<ITotalSalesResponse>(
    [TOTALSALES.TOTALSALESLISTS],
    ({ pageParam = 1 }) => fetchSalesData(pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length + 1;
        // 상품이 0개이거나 rowsPerPage보다 작을 경우 마지막 페이지로 인식한다.
        return lastPage?.totalSales.length === 0 || lastPage?.totalSales.length < 50
          ? undefined
          : nextPage;
      },
      enabled: !!searchTrigger,
      retry: 0,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const newData = data.pages.flatMap((page) => page.totalSales);
        setTotalSalesLists(newData);
        setSearchTrigger(false);
      },
    },
  );

  return {
    totalSalesLists,
    isLoading,
    isError,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    setSearchTrigger,
    hasNextPage,
  };
};
