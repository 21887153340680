import { AUTH } from '@dataTypes/authTypes';
import { grantUserListData } from '@dataTypes/grantAuthority';
import React from 'react';

interface MemberItemProps {
  loginId: string;
  name: string;
  phoneNumber: string;
  auth: AUTH;
  showConfirmModalWithLoginId: (user: grantUserListData) => void;
}

const MemberItem = ({
  auth,
  loginId,
  name,
  phoneNumber,
  showConfirmModalWithLoginId,
}: MemberItemProps) => {
  const convertUserType = () => {
    let result = '';
    switch (auth) {
      case AUTH.USER:
        result = '유저';
        break;
      case AUTH.MANAGER:
        result = '매니저';
        break;
      default:
        break;
    }

    return result;
  };

  const formattedPhoneNumber = () => {
    return phoneNumber.slice(0, 7) + '****';
  };

  return (
    // 수정이 없어서 보류
    // <div className="item_wrapper" onClick={() => showConfirmModalWithLoginId(userData)}>
    <div className="item_wrapper">
      <div className="name">{name}</div>
      <div className="loginId">{loginId}</div>
      <div className="phone">{formattedPhoneNumber()}</div>
      <div className="auth">{convertUserType()}</div>
    </div>
  );
};

export default MemberItem;
