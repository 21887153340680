import { searchStoreUserList } from '@apis/grantAuthority';
import { GRANTAUTHORITY } from '@components/grantAuthority/queryKey/queryKey';
import { getSearchStoreUser } from '@dataTypes/grantAuthority';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosHeaders } from 'axios';
import { RefObject, useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import errorOrYesModalState from '../../recoil/errorOrYesModal/atom';
import { imgTypes } from '@dataTypes/imgTypes';

export const useGetSearchUser = (
  storeId: number | undefined,
  dropdownValue: 'phone' | 'loginId',
  searchInputVal: string,
  targetRef: RefObject<Element>,
) => {
  const queryClient = useQueryClient();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchUserLoading, setSearchUserLoading] = useState(false);
  const [searchUserList, setSearchUserList] = useState<getSearchStoreUser[]>([]);
  const [searchTrigger, setSearchTrigger] = useState(false);
  const setErrorState = useSetRecoilState(errorOrYesModalState);

  // 검색 트리거가 변경될 때마다 currentPage를 초기화
  useEffect(() => {
    if (searchTrigger) {
      setCurrentPage(1);
      queryClient.invalidateQueries([GRANTAUTHORITY.SEARCHUSERLIST]);
      remove();
      setSearchUserList([]);
    }
  }, [searchTrigger]);

  const requestData =
    storeId !== undefined
      ? {
          store: storeId,
          page: currentPage,
          'login-id': dropdownValue === 'loginId' ? searchInputVal : '',
          'phone-number': dropdownValue === 'phone' ? searchInputVal : '',
        }
      : null;

  const { data, hasNextPage, isLoading, isFetching, isFetchingNextPage, remove, fetchNextPage } =
    useInfiniteQuery<getSearchStoreUser[]>(
      [GRANTAUTHORITY.SEARCHUSERLIST, storeId],
      () => searchStoreUserList(requestData),
      {
        getNextPageParam: (lastPage, allPages) => {
          const nextPage = allPages.length + 1;
          const isLast = lastPage?.length === 0 || lastPage?.length < 50;
          if (isLast) {
            return undefined;
          } else {
            return nextPage;
          }
        },
        enabled: !!storeId,
        retry: 0,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
          const newData = data.pages.flatMap((page) => page);
          setSearchUserList(newData);
          setSearchUserLoading(false);
          setSearchTrigger(false);
        },
        onError: (error: AxiosHeaders | unknown) => {
          console.log('error: ', error);

          setSearchUserLoading(false);

          if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 400 && error.response.data?.message) {
              alert('휴대폰 번호는 7자리 이상 입력해주셔야 합니다.');
            } else {
              alert('데이터를 불러오는 중 오류가 발생했습니다.');
            }
          } else {
            alert('알 수 없는 오류가 발생했습니다.');
          }
        },
        onSettled: () => {
          setSearchUserLoading(false);
        },
      },
    );

  useEffect(() => {
    if (currentPage > 1) {
      fetchNextPage();
    }
  }, [currentPage]);

  useEffect(() => {
    if (hasNextPage) {
      const lastElement = targetRef.current;

      const observer = new IntersectionObserver((entries) => {
        const isIntersecting = entries[0].isIntersecting;
        if (isIntersecting) {
          setCurrentPage((prevState) => prevState + 1);
        }
      });

      if (lastElement) {
        observer.observe(lastElement);
      }

      return () => {
        observer.disconnect();
      };
    }
  }, [searchUserList]);

  // 로딩 상태 관리
  useEffect(() => {
    setSearchUserLoading(isLoading || isFetching);
  }, [isLoading, isFetching]);

  return {
    searchUserList,
    searchUserLoading,
    isFetchingNextPage,
    setSearchTrigger,
  };
};
