import CommonHeader from '@components/common/CommonHeader';
import DashBoardHeader from '@components/salesDashBoard/DashBoardHeader';
import React from 'react';
import CctvContainer from '../../components/cctv/CctvContainer';

const CCTVPage = () => {
  return (
    <div id="cctv_page_container">
      <CommonHeader>CCTV</CommonHeader>
      <CctvContainer />
    </div>
  );
};

export default CCTVPage;
