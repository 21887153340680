import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import storeInfo from '../../recoil/storeInfo/atom';
import { useCCTVList } from '@hooks/cctv/useCCTVList';
import { IAddCCTVRequest } from '@dataTypes/cctvTypes';
import { addCCTV, modifyCCTV } from '@apis/cctv';
import { CCTV } from './queryKey/queryKey';
import { AxiosHeaders } from 'axios';
import { Button, Form } from 'react-bootstrap';
import errorOrYesModalState from '../../recoil/errorOrYesModal/atom';
import { imgTypes } from '@dataTypes/imgTypes';
import { useNavigate } from 'react-router-dom';

interface CctvData {
  cctvUser: string;
  cctvPassword: string;
  ipAddress: string;
}

const CCTVModifyContainer = () => {
  const queryClient = useQueryClient();
  const currentUser = useRecoilValue(storeInfo);
  const navigation = useNavigate();
  const setErrorState = useSetRecoilState(errorOrYesModalState);
  const [cctvData, setCctvData] = useState<CctvData>({
    cctvUser: '',
    cctvPassword: '',
    ipAddress: '',
  });

  const { getCCTVListData, getCCTVListFetching, getCCTVListLoading } = useCCTVList(
    currentUser.currentStoreId,
  );

  useEffect(() => {
    if (getCCTVListData && getCCTVListData?.length > 0) {
      const { ipAddress, cctvUser, cctvPassword } = getCCTVListData[0];
      setCctvData({
        cctvUser: cctvUser,
        cctvPassword: cctvPassword,
        ipAddress: ipAddress,
      });
    }
  }, [getCCTVListData]);

  const { mutateAsync: addCCTVAsync } = useMutation((data: IAddCCTVRequest) => addCCTV(data), {
    onSuccess: () => {
      setErrorState({
        status: true,
        imgType: imgTypes.YES,
        title: 'CCTV 등록 완료',
        modalBG: true,
      });
      queryClient.invalidateQueries([CCTV.CCTVLIST]);
    },
    onError: (error: AxiosHeaders) => {
      setErrorState({
        status: true,
        imgType: imgTypes.ERROR,
        title: error.response.data.message,
        modalBG: true,
      });
    },
  });

  const { mutateAsync: modifyCCTVAsync } = useMutation(
    (data: IAddCCTVRequest) => modifyCCTV(data),
    {
      onSuccess: () => {
        setErrorState({
          status: true,
          imgType: imgTypes.YES,
          title: 'CCTV 수정 완료',
          modalBG: true,
        });
        queryClient.invalidateQueries([CCTV.CCTVLIST]);
      },
      onError: (error: AxiosHeaders) => {
        setErrorState({
          status: true,
          imgType: imgTypes.ERROR,
          title: error.response.data.message,
          modalBG: true,
        });
      },
    },
  );

  // 입력 핸들러
  const newInputHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id, value } = e.target;
    setCctvData((prevState) => ({
      ...prevState,
      [id]: value, // id가 password인 경우 password 필드를 업데이트
    }));
  };

  const addCCTVHandler = async () => {
    if (currentUser && currentUser.currentStoreId) {
      const data: IAddCCTVRequest = {
        cctvUser: cctvData.cctvUser,
        cctvPassword: cctvData.cctvPassword,
        ipAddress: cctvData.ipAddress,
        store: currentUser.currentStoreId,
      };
      addCCTVAsync(data);
    }
  };

  const modifyCCTVHandler = async () => {
    if (currentUser && currentUser.currentStoreId) {
      const data: IAddCCTVRequest = {
        cctvUser: cctvData.cctvUser,
        cctvPassword: cctvData.cctvPassword,
        ipAddress: cctvData.ipAddress,
        store: currentUser.currentStoreId,
      };
      modifyCCTVAsync(data);
    }
  };

  return (
    <div className="cctv_container_wrapper">
      {/* {currentUser.currentAuthority !== AUTH.USER} */}
      <div className="top_section_wrapper">
        <div className="main_text_wrapper">
          <h1 className="list_title">CCTV 등록</h1>
          <h2 className="list_sub_text">매니저가 CCTV 기기 등록 후 사용 가능합니다.</h2>
        </div>

        {getCCTVListData && getCCTVListData?.length > 0 && (
          <Button className="go_cctv_btn" onClick={() => navigation('/admin/cctv')}>
            CCTV 보기
          </Button>
        )}
      </div>

      <Form.Group className="mb-2 mt-2 cctv_input_wrapper" controlId="cctvName">
        <Form.Control
          className="form_text"
          type="text"
          placeholder="cctv user"
          autoComplete="on"
          id="cctvUser"
          value={cctvData.cctvUser}
          onChange={newInputHandler}
        />

        <Form.Control
          className="form_text"
          type="password"
          placeholder="cctv password"
          autoComplete="on"
          id="cctvPassword"
          value={cctvData.cctvPassword}
          onChange={newInputHandler}
        />

        <Form.Control
          className="form_text"
          type="text"
          placeholder="cctv ip address"
          autoComplete="on"
          id="ipAddress"
          value={cctvData.ipAddress}
          onChange={newInputHandler}
        />
      </Form.Group>
      {getCCTVListData && getCCTVListData?.length > 0 ? (
        <Button className="confirm_btn" onClick={modifyCCTVHandler}>
          cctv 수정
        </Button>
      ) : (
        <Button className="confirm_btn" onClick={addCCTVHandler}>
          cctv 등록
        </Button>
      )}

      <ul className="cctv_info_text">
        <li>하이크 비전 연동 문의는 1811-5998에 문의 바랍니다.</li>
        <li>하이크 비전의 DS-7616NXI-K2/16P 제품만 지원합니다.</li>
        <li>하이크 비전의 아이디와 비밀번호, 그리고 CCTV와 연결된 IP를 등록하셔야 합니다.</li>
      </ul>
    </div>
  );
};
export default CCTVModifyContainer;
