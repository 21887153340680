import { machineListsData } from '@dataTypes/perMachineTypes';
import { PAYMETHOD } from '@dataTypes/totalSalesTypes';
import React from 'react';
import { Form } from 'react-bootstrap';

interface SelectTypesProps {
  setSelectedMachine: React.Dispatch<React.SetStateAction<PAYMETHOD>>;
  machineLists: machineListsData[] | undefined;
  setSelectedCatId: React.Dispatch<React.SetStateAction<string>>;
}

const SelectTypes = ({ setSelectedMachine, machineLists, setSelectedCatId }: SelectTypesProps) => {
  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedMachine(event.target.value as PAYMETHOD);
  };

  const changedSelectedCatId = (event: React.FormEvent<HTMLSelectElement>) => {
    setSelectedCatId(event.currentTarget.value);
  };

  return (
    <div className="select_types_wrapper">
      <Form.Select
        className="type_button"
        defaultValue={''}
        onChange={changedSelectedCatId}
        aria-label="Default select example"
      >
        <option value={''}>단말기 전체</option>
        {machineLists?.map((list, index) => (
          <option key={list.deviceId} value={list.catId}>
            {list.deviceAlias}
          </option>
        ))}
      </Form.Select>
      <Form.Select
        className="type_button"
        aria-label="Default select example"
        defaultValue={PAYMETHOD.TOTAL}
        onChange={handleSelectChange}
      >
        <option disabled={true} value={PAYMETHOD.TOTAL}>
          결제수단 전체
        </option>
        <option value={PAYMETHOD.TOTAL}>전체</option>
        <option value={PAYMETHOD.CARD}>카드</option>
        <option value={PAYMETHOD.CASH}>마일리지</option>
        <option value={PAYMETHOD.ETC}>기타</option>
      </Form.Select>
    </div>
  );
};

export default SelectTypes;
