import { atom } from 'recoil';
import { IStoreResponseData } from '@dataTypes/userInfo';
import { recoilPersist } from 'recoil-persist';
import { INavActive } from './types';

const { persistAtom } = recoilPersist();

const navActive = atom<INavActive>({
  key: 'navActive',
  default: { dashboard: true, totalsales: false, permachine: false, grant: false },
  effects_UNSTABLE: [persistAtom],
});

export default navActive;
