import { bulkInsertMachineByMaster } from '@apis/perMachine';
import { insertMachineData } from '@dataTypes/perMachineTypes';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useState, useRef } from 'react';
import * as XLSX from 'xlsx';
import { PERMACHINE } from './queryKey/queryKey';
import { useSetRecoilState } from 'recoil';
import errorOrYesModalState from '../../recoil/errorOrYesModal/atom';
import { imgTypes } from '@dataTypes/imgTypes';
import { AxiosHeaders } from 'axios';

const ExcelRead = () => {
  const queryClient = useQueryClient();
  const setErrorState = useSetRecoilState(errorOrYesModalState);
  const [fileData, setFileData] = useState<insertMachineData[]>([]);
  const fileInputRef = useRef<HTMLInputElement | null>(null); // 파일 입력 필드에 접근하기 위한 ref 추가

  const { mutateAsync: bulkInsertMachineByMasterAsync } = useMutation(
    (data: insertMachineData[]) => bulkInsertMachineByMaster(data),
    {
      onSuccess: (result) => {
        if (result) {
          setErrorState({
            status: true,
            imgType: imgTypes.YES,
            title: '등록 완료',
            text: '단말기 일괄 등록이 완료되었습니다.',
            modalBG: true,
          });
          queryClient.invalidateQueries([PERMACHINE.PERMACHINESALESLISTS]);
          setFileData([]); // 성공 시 배열 초기화
          resetFileInput(); // 파일 입력 필드 초기화
        } else {
          setErrorState({
            status: true,
            imgType: imgTypes.ERROR,
            title: '등록 실패',
            text: '엑셀 형식이 가이드와 다른지 확인해주세요. result:false',
            modalBG: true,
          });
          setFileData([]); // 실패 시 배열 초기화
          resetFileInput(); // 파일 입력 필드 초기화
        }
      },
      onError: (error: AxiosHeaders) => {
        setFileData([]); // 오류 시 배열 초기화
        resetFileInput(); // 파일 입력 필드 초기화
        if (error.response.status === 400) {
          setErrorState({
            status: true,
            imgType: imgTypes.ERROR,
            title: error.response.data.message,
            modalBG: true,
          });
        } else {
          setErrorState({
            status: true,
            imgType: imgTypes.ERROR,
            title: '엑셀 형식이 가이드와 다른지 확인해주세요. onError',
            modalBG: true,
          });
        }
      },
    },
  );

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      readFile(selectedFile); // 파일을 읽고 처리
    } else {
      setFileData([]); // 파일 선택 취소 시 배열 초기화
      resetFileInput(); // 파일 입력 필드 초기화
    }
  };

  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // 파일 입력 필드의 값을 빈 문자열로 설정하여 초기화
    }
  };

  const readFile = async (file: File) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (!e.target?.result) return;

      const data = new Uint8Array(e.target.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: 'array' });

      const firstWorksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData: [string, string, number][] = XLSX.utils.sheet_to_json(firstWorksheet, {
        header: 1,
      });
      let slicedJsonData = jsonData.slice(1).filter((row) => row.length > 0);

      if (slicedJsonData) {
        if (Array.isArray(slicedJsonData) && slicedJsonData.length > 0) {
          const updatedData: insertMachineData[] = slicedJsonData.map(
            (item: [string, string, number]) => ({
              catId: item[0], // 첫 번째 값 -> storeId
              deviceAlias: item[1], // 두 번째 값 -> storeName
              storeId: item[2], // 세 번째 값 -> businessNum
            }),
          );
          setFileData(updatedData); // 상태에 데이터 저장
          bulkInsertMachineByMasterAsync(updatedData);
        }
      }
    };
    reader.readAsArrayBuffer(file);
  };

  return (
    <div className="excel_input_wrapper">
      <label htmlFor="excel_input">단말기 일괄 등록</label>
      <input
        type="file"
        onChange={handleFileChange}
        accept=".xlsx, .xls"
        id="excel_input"
        ref={fileInputRef} // 파일 입력 필드에 ref 연결
      />
    </div>
  );
};

export default ExcelRead;
