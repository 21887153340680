import { useQuery, useQueryClient } from '@tanstack/react-query';
import { TOTALSALES } from '@components/totalSales/queryKey/queryKey';
import React, { useEffect, useMemo, useState } from 'react';
import {
  ISumSalesList,
  ITotalSalesItem,
  ITotalSalesResponse,
  PAYMETHOD,
} from '@dataTypes/totalSalesTypes';
import { getSumSalesWithPerMachine, getSumSalesWithTotalMachine } from '@apis/totalSales';
import SumSalesList from '../../components/totalSales/SumSalesList';

interface useGetTotalSalesListsProps {
  startDate: string;
  endDate: string;
  catId: string;
  method: PAYMETHOD;
  storeId: number | undefined;
  showSumList: boolean;
}

export const useGetSumSalesLists = ({
  startDate,
  endDate,
  catId,
  method,
  storeId,
  showSumList,
}: useGetTotalSalesListsProps) => {
  const queryClient = useQueryClient();
  const [searchSumTrigger, setSearchSumTrigger] = useState(false);
  const [sumSalesListData, setSumSalesListData] = useState<ISumSalesList | undefined>({
    totalSales: 0,
    etcSales: 0,
    cashSales: 0,
    cardSales: 0,
  });

  useEffect(() => {
    if (searchSumTrigger) {
      queryClient.invalidateQueries([TOTALSALES.SUMSALESLISTS]);
      remove();
    }
  }, [searchSumTrigger]);

  const fetchSalesData = () => {
    if (catId === '') {
      return getSumSalesWithTotalMachine(startDate, endDate, storeId, method);
    } else {
      return getSumSalesWithPerMachine(startDate, endDate, catId, method);
    }
  };

  const {
    data,
    isLoading: sumLoading,
    isFetching: sumFetching,
    remove,
  } = useQuery<ISumSalesList>([TOTALSALES.SUMSALESLISTS], () => fetchSalesData(), {
    onSettled: (data) => {
      setSearchSumTrigger(false);
      setSumSalesListData(data);
    },
    enabled: !!searchSumTrigger && !!showSumList,
  });

  return {
    sumSalesListData,
    sumLoading,
    sumFetching,
    setSearchSumTrigger,
  };
};
