import { IAddCCTVRequest } from '@dataTypes/cctvTypes';
import { instance } from './index';

export const addCCTV = async (data: IAddCCTVRequest) => {
  const response = await instance.post('/user/store/cctv/add', data);
  return response.data;
};

export const modifyCCTV = async (data: IAddCCTVRequest) => {
  const response = await instance.put('/user/store/cctv/edit', data);
  return response.data;
};

export const getCCTVList = async (storeId: number | undefined) => {
  const response = await instance.get(`/user/store/${storeId}/cctv`);
  return response.data;
};
