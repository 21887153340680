import React from 'react';
import nextArrowIcon from '@assets/icon/next_arrow_icon.png';
import { ITotalSalesItem, PAYMETHOD, TRANSACTION } from '@dataTypes/totalSalesTypes';
import { format } from 'date-fns';

interface TotalSalesListsProps {
  showDetailPayment: (id: number) => void;
  item: ITotalSalesItem;
}

const TotalSalesLists = ({ showDetailPayment, item }: TotalSalesListsProps) => {
  const formatTransactionDate = (transactionDate: string) => {
    const datePart = transactionDate.slice(0, 6); // "240601"
    const timePart = transactionDate.slice(6); // "000002"

    // 날짜 포맷팅
    const year = datePart.slice(0, 2); // "24"
    const month = datePart.slice(2, 4); // "06"
    const day = datePart.slice(4, 6); // "01"
    const formattedDate = `${year}-${month}-${day}`; // "24-06-01"

    // 시간 포맷팅
    const hours = timePart.slice(0, 2); // "00"
    const minutes = timePart.slice(2, 4); // "00"
    const seconds = timePart.slice(4, 6); // "02"
    const formattedTime = `${hours}:${minutes}:${seconds}`; // "00:00:02"

    return [formattedDate, formattedTime];
  };

  const [formattedDate, formattedTime] = formatTransactionDate(item.transactionDate);

  const formattedMethod = (method: PAYMETHOD, status: TRANSACTION) => {
    if (status === TRANSACTION.C) {
      switch (method) {
        case PAYMETHOD.CARD:
          return '카드 취소';
        case PAYMETHOD.CASH:
          return '마일리지 취소';
        case PAYMETHOD.ETC:
          return '기타 취소';
        default:
          return '알 수 없음 취소';
      }
    } else {
      switch (method) {
        case PAYMETHOD.CARD:
          return '카드';
        case PAYMETHOD.CASH:
          return '마일리지';
        case PAYMETHOD.ETC:
          return '기타';
        default:
          return '알 수 없음';
      }
    }
  };

  const addCommas = (number: number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  return (
    <li className="sales_list" onClick={() => showDetailPayment(item.id)}>
      <section className="top_section">
        <div className="sales_date">
          <span className="date">{formattedDate}</span>
          <span className="time">{formattedTime}</span>
        </div>
        <div className={`pay_type ${item.transactionStatus === TRANSACTION.C ? 'cancel' : ''}`}>
          {formattedMethod(item.method, item.transactionStatus)}
        </div>
      </section>

      <section className="bottom_section">
        <div className="money_wrapper">
          <div className="price">
            <div className="text">금액</div>
            <div className="price_money">{addCommas(item.amount)}</div>
          </div>
          <div className="vat">
            <div className="text">부가세</div>
            <div className="vat_money">{addCommas(item.tax)}</div>
          </div>

          {/* <div className="charge">
            <div className="text">수수료</div>
            <div className="charge_money">{addCommas(item.serviceCharge)}</div>
          </div> */}
        </div>

        <div className="arrow_icon_wrapper">
          <img src={nextArrowIcon} className="detail_arrow_icon" alt="상세보기 버튼" />
        </div>
      </section>
    </li>
  );
};

export default TotalSalesLists;
