import {
  IStore,
  IStoreChangeData,
  IStoreRenewalResponseData,
  IStoreResponseData,
} from '@dataTypes/userInfo';
import { instance } from './index';
import { IDashBoardRenewalRequest } from '@dataTypes/dashBoardSummary';

export const getDashboardSummaryWithMaster = async () => {
  const response = await instance.get(`/master/dashboard/summary`);
  return response.data;
};

export const getDashboardSummaryWithUser = async (storeId: number | undefined) => {
  if (storeId === undefined) {
    return;
  }
  const response = await instance.get(`/user/dashboard/summary/store/${storeId}`);
  return response.data;
};

export const getUserMainData = async () => {
  const response = await instance.get(`/user/init/main`);
  return response.data;
};

export const getMasterMainData = async () => {
  const response = await instance.get(`/master/init/main`);
  return response.data;
};

export const changeStore = async (storeId: number): Promise<IStoreChangeData> => {
  const response = await instance.get(`/user/switch/store/${storeId}`);
  return response.data;
};

export const changeStoreByMaster = async (storeId: number): Promise<IStoreChangeData> => {
  const response = await instance.get(`/master/switch/store/${storeId}`);
  return response.data;
};

export const renewStoreListsMaster = async (): Promise<IStoreRenewalResponseData> => {
  const response = await instance.get(`/master/renew/storelists`);
  return response.data;
};

export const getManagerBranches = async (): Promise<IStoreResponseData> => {
  const response = await instance.get(`/user/store/manager/branches`);
  return response.data;
};

export const getDashboardMonthSalesList = async (storeId: number | undefined, date: string) => {
  if (storeId === undefined) {
    return;
  }
  const response = await instance.get(`/user/monthly/${storeId}/${date}`);
  return response.data;
};

export const renewalDashboardByMaster = async (data: IDashBoardRenewalRequest) => {
  const response = await instance.put(`/master/renewal/dashboard`, data);
  return response.data;
};
