import { checkLogin } from '@apis/signIn';
import SignInForm from '@components/signIn/SignInForm';
import { Link, useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import storeInfo from '../../recoil/storeInfo/atom';

const SignIn = () => {
  const navigation = useNavigate();
  const setCurrentStoreInfo = useSetRecoilState(storeInfo);

  const logoutHandler = async () => {
    setCurrentStoreInfo({
      currentStore: '',
      currentAuthority: '',
      currentStoreId: undefined,
      storeLists: [],
    });
  };

  const checkLoginHandler = async () => {
    const response = await checkLogin();
    if (response) {
      navigation('/admin/dashboard');
    } else {
      logoutHandler();
    }
  };

  checkLoginHandler();

  return (
    <div id="sign_in_container">
      <h1 className="main_title">매출내역 조회 서비스</h1>
      <h2 className="sub_text">
        SMARTLAB에 로그인 후, 언제 어디서나 <br />
        손쉽게 매출 내역을 확인하세요
      </h2>
      <SignInForm />
      <div className="btn_wrapper">
        <Link to={'/signup'} className="sign_up_btn">
          회원가입
        </Link>
        {/* 나중에 해야함 */}
        {/* <Link to={'/signup'} className="find_pw_btn">
          비밀번호 찾기
        </Link> */}
      </div>

      <span style={{ margin: '1rem auto 0', textAlign: 'center', width: '100%', display: 'block' }}>
        고객센터 02-2632-6599
      </span>
    </div>
  );
};

export default SignIn;
