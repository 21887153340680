import CCTVModifyContainer from '@components/cctv/CCTVModifyContainer';
import CommonHeader from '@components/common/CommonHeader';
import React from 'react';
import { useRecoilValue } from 'recoil';
import navActive from '../../recoil/navActive/atom';
import { useNavigate } from 'react-router-dom';

const CCTVModify = () => {
  const navActiveValue = useRecoilValue(navActive);

  const findUrlHandler = () => {
    let url = '';
    if (navActiveValue.dashboard) {
      url = '/admin/dashboard';
    } else if (navActiveValue.totalsales) {
      url = '/admin/totalsales';
    } else if (navActiveValue.permachine) {
      url = '/admin/permachine';
    } else if (navActiveValue.grant) {
      url = '/admin/grant';
    } else {
      url = '/admin/dashboard';
    }

    return url;
  };

  return (
    <div id="cctv_page_container">
      <CommonHeader backBtnUrl={findUrlHandler()}>CCTV</CommonHeader>
      <CCTVModifyContainer />
    </div>
  );
};

export default CCTVModify;
