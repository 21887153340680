import { formattedMoney } from '@services/common/formattedMoney';
import { endOfMonth, format, startOfMonth, subMonths } from 'date-fns';
import React from 'react';
import { BsTriangleFill } from 'react-icons/bs';

interface TotalListsProps {
  todayDailySales: number;
  yesterdayDailySales: number;
  totalMonthlySales: number;
  lastMonthlySales: number;
}

const TotalLists = ({
  lastMonthlySales,
  todayDailySales,
  totalMonthlySales,
  yesterdayDailySales,
}: TotalListsProps) => {
  const today = new Date();
  const startOfThisMonth = startOfMonth(today);
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);
  const startOfLastMonth = startOfMonth(subMonths(today, 1));
  const endOfLastMonth = endOfMonth(subMonths(today, 1));
  // const truncatedIncreasePercentage = Math.floor(increasePercentage * 10) / 10;

  /*
  increaseAmount: -63751700
  increasePercentage: -42.81173245227458
  */
  return (
    <ul className="total_list_wrapper">
      <li className="total_list">
        <div className="title_wrapper">
          <span className="date">{format(today, 'MM/dd')}</span>
          <span className="sub_text today">오늘</span>
        </div>
        <div className="money">{todayDailySales.toLocaleString('ko-KR')}</div>
      </li>

      <li className="total_list">
        <div className="title_wrapper">
          <span className="date">{format(yesterday, 'MM/dd')}</span>
          <span className="sub_text">어제</span>
        </div>
        <div className="money with_section">
          <div className="left_section">
            <div className="yesterday_daily_money">
              {yesterdayDailySales.toLocaleString('ko-KR')}
            </div>
          </div>
        </div>
      </li>

      <li className="total_list">
        <div className="title_wrapper">
          <span className="date">
            {format(startOfThisMonth, 'MM-dd')} ~ {format(today, 'MM/dd')}
          </span>
          <span className="sub_text">이번달</span>
        </div>
        <div className="money">{totalMonthlySales.toLocaleString('ko-KR')}</div>
      </li>

      <li className="total_list">
        <div className="title_wrapper">
          <span className="date">
            {format(startOfLastMonth, 'MM-dd')} ~ {format(endOfLastMonth, 'MM/dd')}
          </span>
          <span className="sub_text">지난달</span>
        </div>
        <div className="money">{lastMonthlySales.toLocaleString('ko-KR')}</div>
      </li>
    </ul>
  );
};

export default TotalLists;
{
  /* <div className="increase_percent_wrapper">
              <BsTriangleFill className={increasePercentage < 0 ? 'minus' : 'plus'} />
              <div className="percent">{truncatedIncreasePercentage}%</div>
            </div> */
}

{
  /* <div className="right_section">
            <span className="sub_text">지난달 대비</span>
            <div className="month_ago">
              <BsTriangleFill className={increasePercentage < 0 ? 'minus' : 'plus'} />
              <span>{formattedMoney(oneMonthAgoYesterdayDailySales)}</span>
            </div>
          </div> */
}
