import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Offcanvas, OverlayTrigger, Popover } from 'react-bootstrap';
import DashBoardLeftNavBar from '@components/salesDashBoard/DashBoardLeftNavBar';
import loginUserIcon from '@assets/icon/user_icon.png';
import hamburgerIcon from '@assets/icon/hamburger_btn_icon.png';
import cctvIcon from '@assets/icon/cctv_icon(white).png';
import iotIcon from '@assets/icon/iot_icon(white).png';
import { useNavigate } from 'react-router-dom';
import { logoutRequest } from '@apis/signIn';
import { useRecoilState } from 'recoil';
import storeInfo from '../../recoil/storeInfo/atom';
import { AUTH } from '@dataTypes/authTypes';
import {
  changeStore,
  changeStoreByMaster,
  getMasterMainData,
  getUserMainData,
  renewStoreListsMaster,
} from '@apis/salesDashboard';
import { IStoreResponseData, IStoreChangeData } from '@dataTypes/userInfo';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { SALESDASHBOARD } from './queryKey/queryKey';
import Cookies from 'js-cookie';
import DashBoardNav from './DashBoardNav';
import ExcelRead from '@components/perMachine/ExcelRead';
import { useCCTVList } from '@hooks/cctv/useCCTVList';

interface DashBoardHeaderProps {
  displayNone?: boolean;
}

const DashBoardHeader = ({ displayNone }: DashBoardHeaderProps) => {
  const authority = Cookies.get('smartlab_authority');
  const navigation = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [shouldFetch, setShouldFetch] = useState(false);
  const [currentStoreInfo, setCurrentStoreInfo] = useRecoilState(storeInfo);
  const [fetchCCTVData, setFetchCCTVData] = useState(false);
  const { getCCTVListData, getCCTVListFetching, getCCTVListLoading } = useCCTVList(
    fetchCCTVData ? currentStoreInfo.currentStoreId : undefined,
  );

  const cctvSeparationHandler = () => {
    setFetchCCTVData(true); // 버튼 클릭 시 요청을 트리거
  };

  useEffect(() => {
    if (fetchCCTVData && getCCTVListData && getCCTVListData.length > 0) {
      navigation('/admin/cctv');
    } else if (fetchCCTVData) {
      navigation('/admin/cctv/modify');
    }
  }, [fetchCCTVData, getCCTVListData]);

  const logoutHandler = async () => {
    const response = await logoutRequest();
    if (response) {
      setCurrentStoreInfo({
        currentStore: '',
        currentAuthority: '',
        currentStoreId: undefined,
        storeLists: [],
      });
      // queryClient.invalidateQueries();
      navigation('/signin');
    } else {
      console.error('logoutHandler error');
    }
  };

  useEffect(() => {
    if (
      currentStoreInfo.currentStore === '' &&
      currentStoreInfo.currentAuthority === '' &&
      currentStoreInfo.currentStoreId === undefined &&
      currentStoreInfo.storeLists.length === 0
    ) {
      setShouldFetch(true);
    } else {
      setShouldFetch(false);
    }
  }, []);

  const {
    data: getCurrentListsData,
    isLoading: getInitDataLoading,
    isFetching: getInitDataFetching,
  } = useQuery<IStoreResponseData>(
    [SALESDASHBOARD.DASHBOARDCURRENTLISTS],
    () => separationAuthInit(),
    {
      enabled: shouldFetch,
    },
  );

  const separationAuthInit = () => {
    if (authority === AUTH.MASTER) {
      console.log('master');
      return getMasterMainData(); // 마스터 권한일 때 호출
    } else if (authority === AUTH.MANAGER) {
      console.log('manager');
      return getUserMainData(); // 그 외 권한일 때 호출
    } else {
      console.log('else');
      return getUserMainData(); // 그 외 권한일 때 호출
    }
  };

  useEffect(() => {
    //마스터는 최초에 목록 최신화(지점 등록 상황)
    if (authority === AUTH.MASTER) {
      renewalStoreListsHandler();
    }
  }, []);

  useEffect(() => {
    if (getCurrentListsData && shouldFetch) {
      setCurrentStoreInfo({
        currentStore: getCurrentListsData.currentStore,
        currentAuthority: getCurrentListsData.currentAuthority,
        currentStoreId: getCurrentListsData.currentStoreId,
        storeLists: getCurrentListsData.storeLists,
      });

      console.log('getCurrentListsData: ', getCurrentListsData);
    }
  }, [getCurrentListsData, shouldFetch]);

  const changeIndexHandler = async (storeId: number) => {
    console.log('asdfkljsadlkfdsafds');
    if (authority === AUTH.MASTER) {
      const response: IStoreChangeData = await changeStoreByMaster(storeId);
      if (response) {
        setCurrentStoreInfo((prevState) => ({
          ...prevState,
          currentStore: response.currentStore,
          currentAuthority: response.currentAuthority || '',
          currentStoreId: response.storeId,
        }));

        console.log('changeIndexHandler response: ', response);
        renewalStoreListsHandler();
      }
    } else {
      const response: IStoreChangeData = await changeStore(storeId);
      if (response) {
        setCurrentStoreInfo((prevState) => ({
          ...prevState,
          currentStore: response.currentStore,
          currentAuthority: response.currentAuthority || '',
          currentStoreId: response.storeId,
        }));
      }
    }
  };

  const renewalStoreListsHandler = async () => {
    const renewStoreListsMasterResult = await renewStoreListsMaster();
    if (renewStoreListsMasterResult) {
      setCurrentStoreInfo((prevState) => ({
        ...prevState,
        storeLists: renewStoreListsMasterResult.storeLists,
      }));
      console.log('renewalStoreListsHandler: ', renewStoreListsMasterResult);
    } else {
      console.error('마스터 지점 목록 갱신 오류');
    }
  };

  const formattedAuth = () => {
    const auth = currentStoreInfo.currentAuthority;
    if (auth === AUTH.MASTER) {
      return '마스터';
    } else if (auth === AUTH.MANAGER) {
      return '매니저';
    } else {
      return '유저';
    }
  };

  // const cctvSeparationHandler = () => {
  //   if (getCCTVListData && getCCTVListData?.length > 0) {
  //     navigation('/admin/cctv');
  //   } else {
  //     navigation('/admin/cctv/modify');
  //   }
  // };

  return (
    <>
      <div className="dashboard_header">
        <div onClick={handleShow}>
          <img className="hamburger_btn" src={hamburgerIcon} alt="햄버거 버튼" />
        </div>

        {/* <div className="current_store_wrapper">
          <div className="store_name">{currentStoreInfo.currentStore}</div>
          <div className="store_auth">{formattedAuth()}</div>
        </div> */}

        <div className="iot_cctv_wrapper">
          {authority === AUTH.MASTER && <ExcelRead />}
          {authority !== AUTH.USER && (
            <>
              <Button className="cctv_btn_wrapper" onClick={cctvSeparationHandler}>
                <img src={cctvIcon} alt="cctv 아이콘" className="icon" />
                <div className="text">CCTV</div>
              </Button>
              <Button className="cctv_btn_wrapper" onClick={() => navigation('/admin/hejHome')}>
                <img src={iotIcon} alt="cctv 아이콘" className="icon" />
                <div className="text">IOT</div>
              </Button>
            </>
          )}

          <OverlayTrigger
            trigger="click"
            placement="bottom"
            rootClose={true}
            overlay={
              <Popover id={`popover-positioned-bottom`} className="custom_popover">
                <Popover.Body>
                  <div className="go_user_info_nav" onClick={() => navigation('/admin/mypage')}>
                    {/* <img className="nav_icon" alt="로그아웃 아이콘" src={logoutIcon} /> */}
                    <div className="title">마이페이지</div>
                  </div>
                  <div className="login_nav" onClick={logoutHandler}>
                    {/* <img className="nav_icon" alt="로그아웃 아이콘" src={logoutIcon} /> */}
                    <div className="title">로그아웃</div>
                  </div>
                </Popover.Body>
              </Popover>
            }
          >
            <div className="login_btn">
              <img className="login_icon" src={loginUserIcon} alt="사용자 아이콘" />
            </div>
          </OverlayTrigger>
        </div>
      </div>

      {!displayNone && (
        <>
          <div className="header_section">
            <Dropdown className="custom_dropdown">
              <Dropdown.Toggle className="reload_wrapper" variant="warning">
                <div className="store_auth_wrapper">
                  <span>{currentStoreInfo?.currentStore}</span>
                  {authority !== AUTH.MASTER && (
                    <span className="store_auth">{formattedAuth()}</span>
                  )}
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {currentStoreInfo.storeLists?.map((list, index) => (
                  <Dropdown.Item
                    href=""
                    key={list.storeId}
                    onClick={() => changeIndexHandler(list.storeId)}
                  >
                    <span>{list.storeName}</span>
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <DashBoardNav />
        </>
      )}
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Body id="offcanvas_container">
          <DashBoardLeftNavBar />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default DashBoardHeader;
