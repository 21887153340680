import { PAYMETHOD } from '@dataTypes/totalSalesTypes';
import { instance } from './index';

//MASTER 단말기 조회
export const getTotalSalesWithMaster = async (
  startDate: string,
  endDate: string,
  catId: string,
  method: PAYMETHOD,
  page: number,
) => {
  const params = {
    'start-date': startDate,
    'end-date': endDate,
    'cat-id': catId === '' ? '' : catId,
    method: method === '' ? 'CARD' : method,
    page: page,
  };
  const response = await instance.get('/master/sales/total', { params });
  return response.data;
};

//USER 단말기 조회
export const getTotalSales = async (
  startDate: string,
  endDate: string,
  catId: string,
  method: PAYMETHOD,
  page: number,
) => {
  const params = {
    'start-date': startDate,
    'end-date': endDate,
    'cat-id': catId === '' ? '' : catId,
    method: method === '' ? '' : method,
    page: page,
  };

  const response = await instance.get(`/user/sales/total`, { params });
  return response.data;
};

//USER 단말기 전체 조회
export const getTotalSalesWithTotalMachine = async (
  startDate: string,
  endDate: string,
  storeId: number | undefined,
  method: PAYMETHOD,
  page: number,
) => {
  const params = {
    'start-date': startDate,
    'end-date': endDate,
    store: storeId ? storeId : '',
    method: method === '' ? '' : method,
    page: page,
  };

  const response = await instance.get(`/user/store/sales/total`, { params });
  return response.data;
};

export const getSumSalesWithPerMachine = async (
  startDate: string,
  endDate: string,
  catId: string,
  method: PAYMETHOD,
) => {
  // method가 빈 문자열일 때는 해당 파라미터를 빼줍니다.
  const methodQuery = method ? `&method=${method}` : '';

  const response = await instance.get(
    `/user/sales/sum?cat-id=${catId}&start-date=${startDate}&end-date=${endDate}${methodQuery}`,
  );
  return response.data;
};

export const getSumSalesWithTotalMachine = async (
  startDate: string,
  endDate: string,
  storeId: number | undefined,
  method: PAYMETHOD,
) => {
  // method가 빈 문자열일 때는 해당 파라미터를 빼줍니다.
  const methodQuery = method ? `&method=${method}` : '';

  const response = await instance.get(
    `user/store/sales/sum?store=${storeId}&start-date=${startDate}&end-date=${endDate}${methodQuery}`,
  );
  return response.data;
};
