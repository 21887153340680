import { useQuery, useQueryClient } from '@tanstack/react-query';
import { CCTV } from '@components/cctv/queryKey/queryKey';
import { getCCTVList } from '@apis/cctv';
import { ICCTVItem } from '@dataTypes/cctvTypes';

// 커스텀 훅 정의: 기계 목록 가져오기
export const useCCTVList = (storeId: number | undefined) => {
  // 기계 목록 가져오기
  const {
    data: getCCTVListData,
    isLoading: getCCTVListLoading,
    isFetching: getCCTVListFetching,
  } = useQuery<ICCTVItem[]>([CCTV.CCTVLIST, storeId], () => getCCTVList(storeId), {
    enabled: !!storeId,
  });

  return {
    getCCTVListData,
    getCCTVListLoading,
    getCCTVListFetching,
  };
};
