import { AUTH } from '@dataTypes/authTypes';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { Nav } from 'react-bootstrap';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { INavActive } from '../../recoil/navActive/types';
import navActive from '../../recoil/navActive/atom';

interface NavActive {
  dashboard: boolean;
  totalsales: boolean;
  permachine: boolean;
  grant: boolean;
}

const DashBoardNav = () => {
  const location = useLocation();
  const authority = Cookies.get('smartlab_authority');
  const pathName = location.pathname;

  const navInitialHandler = () => {
    const pathName = location.pathname.split('/')[2];
    return {
      dashboard: pathName === 'dashboard',
      totalsales: pathName === 'totalsales',
      permachine: pathName === 'permachine',
      grant: pathName === 'grant',
      hejHome: pathName === 'hejHome',
    };
  };
  const navigation = useNavigate();
  const [navActiveState, setNavActiveState] = useRecoilState(navActive);

  const navActiveHandler = (nav: string) => {
    setNavActiveState((prevNavActive) => ({
      ...prevNavActive,
      [nav]: true,
      dashboard: nav === 'dashboard',
      totalsales: nav === 'totalsales',
      permachine: nav === 'permachine',
      grant: nav === 'grant',
    }));

    switch (nav) {
      case 'dashboard':
        navigation('/admin/dashboard');
        break;
      case 'totalsales':
        navigation('/admin/totalsales');
        break;
      case 'permachine':
        navigation('/admin/permachine');
        break;
      case 'grant':
        navigation('/admin/grant');
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (pathName.includes('dashboard') && !navActiveState.dashboard) {
      setNavActiveState({
        dashboard: true,
        grant: false,
        permachine: false,
        totalsales: false,
      });
    } else if (pathName.includes('totalsales') && !navActiveState.totalsales) {
      setNavActiveState({
        dashboard: false,
        grant: false,
        permachine: false,
        totalsales: true,
      });
    } else if (pathName.includes('permachine') && !navActiveState.permachine) {
      setNavActiveState({
        dashboard: false,
        grant: false,
        permachine: true,
        totalsales: false,
      });
    } else if (pathName.includes('grant') && !navActiveState.grant) {
      setNavActiveState({
        dashboard: false,
        grant: true,
        permachine: false,
        totalsales: false,
      });
    }
  }, [location]);

  const findActiveKey = (obj: INavActive): keyof NavActive | undefined => {
    return (Object.keys(obj) as (keyof NavActive)[]).find((key) => obj[key]);
  };

  useEffect(() => {
    findActiveKey(navActiveState);
  }, [navActiveState]);

  return (
    <Nav
      fill
      variant="tabs"
      activeKey={findActiveKey(navActiveState)}
      defaultActiveKey={'dashboard'}
      id="dashboard_nav"
    >
      <Nav.Item onClick={() => navActiveHandler('dashboard')} className="nav_custom_item">
        <Nav.Link eventKey="dashboard" className="nav_section">
          <div className="type_text_wrapper">
            <span className="type">메인화면</span>
          </div>
        </Nav.Link>
      </Nav.Item>
      <Nav.Item onClick={() => navActiveHandler('totalsales')} className="nav_custom_item">
        <Nav.Link eventKey="totalsales" className="nav_section">
          <div className="type_text_wrapper">
            <span className="type">통합매출</span>
          </div>
        </Nav.Link>
      </Nav.Item>
      <Nav.Item onClick={() => navActiveHandler('permachine')} className="nav_custom_item">
        <Nav.Link eventKey="permachine" className="nav_section">
          <div className="type_text_wrapper">
            <span className="type">단말기별 조회</span>
          </div>
        </Nav.Link>
      </Nav.Item>

      {authority !== AUTH.USER && (
        <Nav.Item onClick={() => navActiveHandler('grant')} className="nav_custom_item">
          <Nav.Link eventKey="grant" className="nav_section">
            <div className="type_text_wrapper">
              <span className="type">관리자 권한</span>
            </div>
          </Nav.Link>
        </Nav.Item>
      )}
    </Nav>
  );
};

export default DashBoardNav;
