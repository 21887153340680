import { signInRequest } from '@apis/signIn';
import axios, { AxiosError } from 'axios';
import React, { useState, FormEvent, ChangeEvent, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import navActive from '../../recoil/navActive/atom';

const SignInForm: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [loginFail, setLoginFail] = useState<string | boolean>(false);
  const navigation = useNavigate();
  const setNavActiveState = useSetRecoilState(navActive);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // 폼 제출 기본 동작 방지
    try {
      const result = await signInRequest(email, password, rememberMe);
      if (result) {
        setNavActiveState({
          dashboard: true,
          totalsales: false,
          permachine: false,
          grant: false,
        });
        navigation('/admin/dashboard');
        setLoginFail(false);
      } else {
        setLoginFail(true);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.data.code === 401) {
          setLoginFail(true);
        } else {
          setLoginFail(error.response?.data.message);
        }
      }
    }
  };

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleRememberMeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRememberMe(event.target.checked);
  };

  return (
    <Form className="sign_in_form" onSubmit={handleSubmit}>
      <Form.Group className="mb-2 mt-2" controlId="formBasicEmail">
        <Form.Control
          className="form_text"
          type="text"
          placeholder="아이디를 입력해주세요"
          autoComplete="on"
          value={email}
          onChange={handleEmailChange}
        />
      </Form.Group>

      <Form.Group className="mb-2" controlId="formBasicPassword">
        <Form.Control
          className="form_text"
          type="password"
          placeholder="비밀번호는 5자리 이상입니다"
          autoComplete="on"
          value={password}
          onChange={handlePasswordChange}
        />
      </Form.Group>

      <Form.Group controlId="formBasicCheckbox">
        <Form.Check
          type="checkbox"
          id="remember-me"
          name="remember-me"
          label="자동 로그인"
          checked={rememberMe}
          onChange={handleRememberMeChange}
        />
      </Form.Group>

      {typeof loginFail === 'boolean' && loginFail && (
        <p className="login_fail_text">
          로그인에 실패하였습니다. 아이디 또는 비밀번호를 확인해주세요.
        </p>
      )}

      {typeof loginFail === 'string' && <p className="login_fail_text">{loginFail}</p>}
      <Button className="submit_btn" variant="primary" type="submit">
        로그인
      </Button>
    </Form>
  );
};

export default SignInForm;
