import React, { useEffect, useState } from 'react';
import logo from '@assets/logo/smartLab_logo(color_horizontal).png';
import mainIconWhite from '@assets/icon/main_icon(white).png';
import mainIconColor from '@assets/icon/main_icon(color).png';
import totalIconColor from '@assets/icon/total_icon(color).png';
import totalIconWhite from '@assets/icon/total_icon(white).png';
import perMachineWhite from '@assets/icon/perMachine_icon(white).png';
import perMachineColor from '@assets/icon/perMachine_icon(color).png';
import chargeColor from '@assets/icon/charge_icon(color).png';
import chargeWhite from '@assets/icon/charge_icon(white).png';
import gearColor from '@assets/icon/gear(color).png';
import gearWhite from '@assets/icon/gear(white).png';
import storeWhite from '@assets/icon/store_icon(white).png';
import storeColor from '@assets/icon/store_icon(color).png';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { AUTH } from '@dataTypes/authTypes';

const DashBoardLeftNavBar = () => {
  const location = useLocation();
  const authority = Cookies.get('smartlab_authority');

  const [navActive, setNavActive] = useState({
    main: true,
    total: false,
    perMachine: false,
    charge: false,
    grant: false,
    addStore: false,
  });

  useEffect(() => {
    const path = location.pathname;

    if (path.includes('/admin/permachine')) {
      setNavActive({
        main: false,
        total: false,
        perMachine: true,
        charge: false,
        grant: false,
        addStore: false,
      });
    } else if (path.includes('/admin/total')) {
      setNavActive({
        main: false,
        total: true,
        perMachine: false,
        charge: false,
        grant: false,
        addStore: false,
      });
    } else if (path.includes('/admin/charge')) {
      setNavActive({
        main: false,
        total: false,
        perMachine: false,
        charge: true,
        grant: false,
        addStore: false,
      });
    } else if (path.includes('/admin/grant')) {
      setNavActive({
        main: false,
        total: false,
        perMachine: false,
        charge: false,
        grant: true,
        addStore: false,
      });
    } else if (path.includes('/admin/addstore')) {
      setNavActive({
        main: false,
        total: false,
        perMachine: false,
        charge: false,
        grant: false,
        addStore: true,
      });
      // } else if (path.includes('/admin/cctv')) {
      //   setNavActive({
      //     main: false,
      //     total: false,
      //     perMachine: false,
      //     charge: false,
      //     grant: false,
      //   });
    } else {
      setNavActive({
        main: true,
        total: false,
        perMachine: false,
        charge: false,
        grant: false,
        addStore: false,
      });
    }
  }, [location.pathname]);

  return (
    <div className="dashboard_left_nav_bar">
      <section className="main_section">
        <img className="main_img" src={logo} alt="메인이미지" />
        {/* <div className="main_text">SmartLab</div> */}
      </section>

      <ul className="nav_lists">
        <li className={`nav_item ${navActive.main ? 'active' : ''}`}>
          <Link to="/admin/dashboard" className="nav_link">
            <div className="nav_cover">
              <img
                className="nav_icon"
                alt="메인 화면 아이콘"
                src={navActive.main ? mainIconColor : mainIconWhite}
              />
              <div className="title">메인화면</div>
            </div>
          </Link>
        </li>

        <li className={`nav_item ${navActive.total ? 'active' : ''}`}>
          <Link to="/admin/totalsales" className="nav_link">
            <div className="nav_cover">
              <img
                className="nav_icon"
                alt="통합 매출 아이콘"
                src={navActive.total ? totalIconColor : totalIconWhite}
              />
              <div className="title">통합매출</div>
            </div>
          </Link>
        </li>

        <li className={`nav_item ${navActive.perMachine ? 'active' : ''}`}>
          <Link to="/admin/permachine" className="nav_link">
            <div className="nav_cover">
              <img
                className="nav_icon"
                alt="단말기별 조회 아이콘"
                src={navActive.perMachine ? perMachineColor : perMachineWhite}
              />
              <div className="title">단말기별 조회</div>
            </div>
          </Link>
        </li>

        {authority !== AUTH.USER && (
          <li className={`nav_item ${navActive.grant ? 'active' : ''}`}>
            <Link to={`/admin/grant`} className="nav_link">
              <div className="nav_cover">
                <img
                  className="nav_icon"
                  alt="관리자 권한 조회 아이콘"
                  src={navActive.grant ? gearColor : gearWhite}
                />
                <div className="title">관리자 권한</div>
              </div>
            </Link>
          </li>
        )}

        <li className={`nav_item ${navActive.charge ? 'active' : ''}`}>
          <Link to="/admin/charge" className="nav_link">
            <div className="nav_cover">
              <img
                className="nav_icon"
                alt="수수료 조회 아이콘"
                src={navActive.charge ? chargeColor : chargeWhite}
              />
              <div className="title">수수료 조회</div>
            </div>
          </Link>
        </li>

        {authority === AUTH.MASTER && (
          <li className={`nav_item ${navActive.addStore ? 'active' : ''}`}>
            <Link to="/admin/addstore" className="nav_link">
              <div className="nav_cover">
                <img
                  className="nav_icon"
                  alt="지점 추가 아이콘"
                  src={navActive.addStore ? storeColor : storeWhite}
                />
                <div className="title">지점 추가</div>
              </div>
            </Link>
          </li>
        )}

        <li className={`nav_item logout_btn`}>
          <span style={{ margin: '0 auto', textAlign: 'center', width: '100%', display: 'block' }}>
            고객센터 02-2632-6599
          </span>
        </li>
      </ul>
    </div>
  );
};

export default DashBoardLeftNavBar;
