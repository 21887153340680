import { ISummaryDayItem } from '@dataTypes/dashBoardSummary';
import dayjs from 'dayjs';
import React from 'react';
interface MonthSalesCalendarProps {
  currentDate: Date;
  monthSalesData: ISummaryDayItem[];
}
const MonthSalesCalendar = ({ currentDate, monthSalesData }: MonthSalesCalendarProps) => {
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;

  const firstDayOfMonth = dayjs(`${currentYear}-${currentMonth}-01`);
  const lastDayOfMonth = firstDayOfMonth.endOf('month');
  const startDay = firstDayOfMonth.startOf('week');
  const endDay = lastDayOfMonth.endOf('week');

  const formatSales = (amount: number) => {
    const truncatedAmount = Math.floor(amount / 1000) / 10; // 소수점 첫 자리만 남기고 버림
    return truncatedAmount.toLocaleString('ko-KR', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 1,
    });
  };

  const getSalesCardDataForDate = (date: dayjs.Dayjs) => {
    const sales = monthSalesData.find((sale) => dayjs(sale.date).isSame(date, 'day'));
    return sales ? formatSales(sales.card) + '만' : null;
  };

  const getSalesCashDataForDate = (date: dayjs.Dayjs) => {
    const sales = monthSalesData.find((sale) => dayjs(sale.date).isSame(date, 'day'));
    return sales ? formatSales(sales.cash) + '만' : null;
  };

  const getSalesEtcDataForDate = (date: dayjs.Dayjs) => {
    const sales = monthSalesData.find((sale) => dayjs(sale.date).isSame(date, 'day'));
    return sales ? formatSales(sales.etc) + '만' : null;
  };

  const daysArray = [];
  let day = startDay;

  while (day.isBefore(endDay) || day.isSame(endDay, 'day')) {
    const isCurrentMonth = day.month() + 1 === currentMonth;

    const salesCard = getSalesCardDataForDate(day);

    const salesCash = getSalesCashDataForDate(day);

    const salesEtc = getSalesEtcDataForDate(day);
    const isSunday = day.day() === 0;
    const isSaturday = day.day() === 6;
    daysArray.push(
      <li
        className={`day_wrapper ${isCurrentMonth ? '' : 'other_month'}`}
        key={day.format('YYYY-MM-DD')}
      >
        <div className={`day ${isSunday ? 'sunday' : ''} ${isSaturday ? 'saturday' : ''}`}>
          {day.date()}
        </div>
        <div className="sales_list">
          {isCurrentMonth && (
            <>
              {salesCard && +salesCard.replace('만', '').replace(',', '') > 0 && (
                <span className="payCard">{salesCard}</span>
              )}
              {salesCash && +salesCash.replace('만', '').replace(',', '') > 0 && (
                <span className="payCash">{salesCash}</span>
              )}
              {salesEtc && +salesEtc.replace('만', '').replace(',', '') > 0 && (
                <span className="payEtc">{salesEtc}</span>
              )}
            </>
          )}
        </div>
      </li>,
    );
    day = day.add(1, 'day');
  }

  const dayOfWeekArray = ['일', '월', '화', '수', '목', '금', '토'].map((day, index) => {
    let dayClass = 'day_of_week';
    if (day === '토') {
      dayClass += ' saturday';
    } else if (day === '일') {
      dayClass += ' sunday';
    }
    return (
      <li className={dayClass} key={index}>
        {day}
      </li>
    );
  });

  return (
    <ul className="calender_container">
      {dayOfWeekArray}
      {daysArray}
    </ul>
  );
};

export default MonthSalesCalendar;
