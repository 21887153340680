import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import storeInfo from '../../recoil/storeInfo/atom';
import { useCCTVList } from '@hooks/cctv/useCCTVList';

const CctvContainer = () => {
  const currentUser = useRecoilValue(storeInfo);

  const { getCCTVListData, getCCTVListFetching, getCCTVListLoading } = useCCTVList(
    currentUser.currentStoreId,
  );

  useEffect(() => {
    getCCTVListHandler();
  }, [getCCTVListData]);

  const getCCTVListHandler = async () => {
    if (
      currentUser &&
      currentUser.currentStoreId &&
      getCCTVListData &&
      getCCTVListData.length > 0
    ) {
      const flutterInAppWebView = window.flutter_inappwebview;
      if (flutterInAppWebView) {
        flutterInAppWebView.callHandler('cctvInfoHandler', getCCTVListData).then((result: any) => {
          console.log(JSON.stringify(result));
        });
      } else {
        console.error('flutter_inappwebview is not available on window');
      }
    }
  };

  return <div className="cctv_container_wrapper">{/* cctv */}</div>;
};

export default CctvContainer;
