import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Button, Offcanvas } from 'react-bootstrap';
import MachineListItem from './MachineListItem';
import Loading from '@components/common/loading/LoadingSpinner';
import { useGetMachineLists } from '../../hooks/perMachine/useGetMachineLists';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { insertMachineData } from '@dataTypes/perMachineTypes';
import { PERMACHINE } from './queryKey/queryKey';
import { insertMachine } from '@apis/perMachine';
import CustomModal from '../common/CustomModal';
import { AxiosHeaders } from 'axios';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import storeInfo from '../../recoil/storeInfo';
import errorOrYesModalState from '../../recoil/errorOrYesModal/atom';
import { imgTypes } from '@dataTypes/imgTypes';
import { AUTH } from '@dataTypes/authTypes';
import Cookies from 'js-cookie';

interface ModifyMachineModalProps {
  show: boolean;
  handleClose: () => void;
}

const ModifyMachineModal = ({ show, handleClose }: ModifyMachineModalProps) => {
  const authority = Cookies.get('smartlab_authority');
  const queryClient = useQueryClient();
  const [showAddInput, setShowAddInput] = useState(false);
  const addCatWrapperRef = useRef<HTMLDivElement | null>(null);
  const currentStoreInfo = useRecoilValue(storeInfo);
  const { getMachineListsData, getMachineListsLoading, getMachineListsFetching } =
    useGetMachineLists(currentStoreInfo?.currentStoreId);
  const [inputCatId, setInputCatId] = useState('');
  const [inputAlias, setInputAlias] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [errorText, setErrorText] = useState(false);
  const setErrorState = useSetRecoilState(errorOrYesModalState);

  useEffect(() => {
    if (showAddInput && addCatWrapperRef.current) {
      addCatWrapperRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [showAddInput]);

  const inputCatIdChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const sanitizedValue = value.replace(/\D/g, '');
    setInputCatId(sanitizedValue);
  };

  const inputAliasChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputAlias(value);
  };

  const { mutateAsync: insertMachineAsync } = useMutation(
    (data: insertMachineData) => insertMachine(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([PERMACHINE.MACHINELISTS]);
        setInputCatId('');
        setInputAlias('');
        setShowAddInput(false);
        setModalShow(true);
        setErrorText(false);
      },
      onError: (error: AxiosHeaders) => {
        /*
        "code": 400,
        "status": "BAD_REQUEST",
        "message": "중복",
        "data": null
        */

        if (error.response.status === 400) {
          setErrorState({
            status: true,
            imgType: imgTypes.ERROR,
            title: `중복된 입력값 입니다`,
            text: `${error.response.data.message}입니다.<br />다른 입력값으로 시도해주세요.`,
            modalBG: true,
          });
        } else if (error.response.status === 401) {
          setErrorState({
            status: true,
            imgType: imgTypes.ERROR,
            title: '인증되지 않은 사용자입니다.',
            text: '로그인 정보가 유효하지 않거나, <br /> 로그인 유효 시간이 경과하였습니다.',
            modalBG: true,
          });
        } else if (error.response.status === 403) {
          setErrorState({
            status: true,
            imgType: imgTypes.ERROR,
            title: '권한이 없어 요청이 거부되었습니다.',
            text: '관리자에게 권한을 요청하거나, <br /> 해당 기능의 권한 여부를 확인해주세요.',
            modalBG: true,
          });
        } else {
          setErrorState({
            status: true,
            imgType: imgTypes.ERROR,
            title: '알 수 없는 오류입니다.',
            text: '로그아웃 후 재시도 해주세요. <br /> 해당 오류가 계속되면 관리자에게 문의해주세요.',
            modalBG: true,
          });
        }
      },
    },
  );

  const insertMachineAsyncHandler = () => {
    const trimmedCatId = inputCatId.trim();
    const trimmedAlias = inputAlias.trim();

    if (
      !(trimmedCatId.length >= 5 && trimmedCatId.length < 10) ||
      !(trimmedAlias.length >= 2 && trimmedAlias.length < 25)
    ) {
      setErrorText(true);
      return;
    }

    if (currentStoreInfo && currentStoreInfo.currentStoreId) {
      const data = {
        catId: inputCatId,
        deviceAlias: inputAlias,
        storeId: currentStoreInfo.currentStoreId,
      };
      insertMachineAsync(data);
    }
  };

  const closeAddInputHandler = () => {
    setInputCatId('');
    setInputAlias('');
    setShowAddInput(false);
  };

  return (
    <>
      <Offcanvas id="modify_modal_wrapper" show={show} onHide={handleClose} placement="bottom">
        <Offcanvas.Header closeButton className="modify_modal_header">
          <Offcanvas.Title>단말기 별칭설정</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="modify_detail_body">
          <div className="sales_date title">
            <span className="title">단말기 번호</span>
            <span className="text">단말기 별칭</span>
          </div>
          {getMachineListsLoading || getMachineListsFetching ? (
            <div className="loading_wrapper">
              <Loading />
            </div>
          ) : (
            <>
              {getMachineListsData?.map((list) => <MachineListItem key={list.catId} item={list} />)}
            </>
          )}

          {showAddInput && (
            <div className="add_cat_container">
              <div className="add_cat_wrapper" ref={addCatWrapperRef}>
                <div className="input_wrapper">
                  <input
                    className="input_cat"
                    type="text"
                    placeholder="cat ID"
                    value={inputCatId}
                    onChange={inputCatIdChangeHandler}
                  />
                  <input
                    className="input_alias"
                    type="text"
                    placeholder="별칭"
                    value={inputAlias}
                    onChange={inputAliasChangeHandler}
                  />
                </div>
                <div className="btn_wrapper">
                  <Button className="confirm" onClick={insertMachineAsyncHandler}>
                    저장
                  </Button>
                  <Button className="cancel" onClick={closeAddInputHandler}>
                    취소
                  </Button>
                </div>
              </div>
              {errorText && (
                <div className="error_text">
                  Cat ID는 5자 ~ 10자의 숫자, 별칭은 2자 ~ 25자사이의 값을 입력해주세요.
                </div>
              )}
            </div>
          )}
          {authority === AUTH.MASTER && (
            <Button
              className={`show_add_input ${showAddInput ? 'active' : ''}`}
              onClick={() => setShowAddInput(true)}
            >
              단말기 추가
            </Button>
          )}
        </Offcanvas.Body>
      </Offcanvas>

      <CustomModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        text={'단말기 등록이 완료되었습니다.'}
      />
    </>
  );
};

export default ModifyMachineModal;
